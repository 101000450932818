<template>
	<div>
		<!-- <div id="Dynamic" v-if="Awardlist.length==1">
			<div class="Dynamic_box">
				<div class="layer"></div>
				<div class="Dynamic_out">
					<div class="container" :style="LeftSty" :class="TranSitionSty">
						<div class="contItem" :name="index" :style="'width:' +WidTh+ 'px;margin-left:' +MarGin+ 'px;margin-right:' +MarGin+ 'px'" v-for="(item,index) in DetailArr[0]" :key="index">
							<div class="cont_pic" style="width: 200px;" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
								<img style="width: 80%" :src="item.cover" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div id="DynamicMore">
			<div class="Dynamic_wrapper">
				<div class="bar"></div>
				<div class="Dynamic_box" v-for="num in Awardlist.length" :key="num">
					<div class="Dynamic_list" :class="TranSitionSty">
						<div class="Dynamic_item"  v-for="(item,index) in DetailArr[num-1]" :key="index" :data-index="index">
							<div class="img-con" :class="['lv'+item.lv]">
								<img style="width: 80%" :src="item.cover" alt="">
							</div>
						</div>
					</div>
					<!-- <div class="Dynamic_out">
						<div class="container" :style="LeftSty" :class="TranSitionSty">
							<div class="contItem" :name="index" :style="'width:' +WidTh+ 'px;margin-left:' +MarGin+ 'px;margin-right:' +MarGin+ 'px'" v-for="(item,index) in DetailArr[num-1]" :key="index">
								<div class="cont_pic" style="width: 200px;" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
									<img style="width: 80%" :src="item.cover" alt="">
								</div>
							</div>
						</div>
					</div> -->
				</div>
				<div class="bar bar-bottom"></div>
			</div>	
		</div>
	</div>
	
</template>

<script>
	export default {
		name: 'Dynamic',
		props: {
			DetailList: { //箱内物品
				type: Array
			},
			Awardlist:{//奖品列表
				type: Array
			},
			Dynamic_checked:{//动画播放
				type:String
			},
			Dynamic_num:{//奖品数量
				type:Number
			},
			Music_checked:{
				type: Boolean
			}
		},

		data() {
			return {
				LeftSize: '0', //偏移距离
				TranSition:'',//过度曲线
				DetailArr: [], //箱内物品(重置后)
				also:0,//假数据(判断是否继续处理)
				process: null, //开奖过程声音
				WidTh:200,
				MarGin:10,
			}
		},
		created() {
			this.process = new Audio(require('@/assets/audio/process.mp3'))
			// console.log(this.process.volume)
			this.process.volume = 0.5
		},

		methods: {
			//拼接并打乱数组
			Arraysplicing() {
				let A_Arr = []
				for (let i = 0; i < 20; i++) {
					A_Arr = A_Arr.concat(this.DetailList)
				}
				// console.log(A_Arr)

				//打乱
				function randSort(A_Arr) {
					for (let i = 0, len = A_Arr.length; i < len; i++) {
						let rand = parseInt(Math.random() * len)
						let temp = A_Arr[rand]
						A_Arr[rand] = A_Arr[i]
						A_Arr[i] = temp
					}
					return A_Arr
				}
				return randSort(A_Arr)
				// console.log(this.DetailArr)
			},
			getDetailArr(){
				this.DetailArr = []
				let DetailArr = []
				for(var i=0;i<this.Awardlist.length;i++){
					let arr = this.Arraysplicing()
					DetailArr.push(arr)
				}
				this.DetailArr = DetailArr
			},
			//处理中奖
			Determine(){
				for(var i=0;i<this.Awardlist.length;i++){
					this.DetailArr[i][31] = this.Awardlist[i]//奖品挪移
					this.DetailArr[i][32] = this.Awardlist[i]//奖品挪移
					this.DetailArr[i][33] = this.Awardlist[i]//奖品挪移
				}
				this.TranSition = 'active'//增加过度动画
				this.LeftSize = -((this.WidTh * 31 + (this.MarGin * 2) * 31) - (this.MarGin)*3 )//偏移值
				if(this.Music_checked){
					this.process.play()//播放声音
				}
				
				setTimeout(() => {
					this.IsRepeat()
				},6000)
				// if(this.also < this.Dynamic_num){
				// 	this.DetailArr[31] = this.Awardlist[this.also]//奖品挪移
				// 	this.DetailArr[32] = this.Awardlist[this.also]//奖品挪移
				// 	this.DetailArr[33] = this.Awardlist[this.also]//奖品挪移
				// 	this.TranSition = 'active'//增加过度动画
				// 	this.LeftSize = -((this.WidTh * 31 + (this.MarGin * 2) * 31) - (this.MarGin)*3 )//偏移值
				// 	this.process.play()//播放声音
				// 	this.also += 1//自加1
				// 	setTimeout(() => {
				// 		this.IsRepeat()
				// 	},6900)
				// }else{
				// 	//子传父(动画结束)
				// 	this.$emit('setAnimation', true)
				// }
			},

			//重复
			IsRepeat(){
				// console.log('Repeat调用')
				let step = this.Awardlist.length==1?1100:100
				if(this.Awardlist.length==1){
					this.$emit('setEquipment', 1)
				}
				// let alsoArr = []
				// alsoArr.unshift(this.DetailArr[31],this.DetailArr[32],this.DetailArr[33])//上次偏移到最后的三件物品
				
				// this.DetailArr = alsoArr.concat(this.DetailArr)//上次最后的三件物品插入新打乱的数组
				
				setTimeout(() => {
					// this.Determine()
					this.getDetailArr()//重新调用打乱
					this.TranSition = ''//清掉过度动画
					this.LeftSize = '0'//偏移值复原
					//子传父(动画结束)
					this.$emit('setAnimation', true)
				},step)
			}
		},

		computed: {
			//偏移样式
			LeftSty() {
				return 'transform: translateX(' + (this.LeftSize) + 'px)'
			},
			//过渡样式
			TranSitionSty(){
				return this.TranSition
			}
		},

		watch: {
			DetailList(arr) {
				if (arr.length > 0) this.getDetailArr()
			},

			//为'1'处理中奖数据并播放动画
			Dynamic_checked(str){
				// this.also = 0
				this.getDetailArr()
				if(str == '1') this.Determine()
			},
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.DynamicWrapper{
		// position: fixed;
		// left: 0;
		// top: 0;
		// right: 0;
		// bottom: 0;
	}
	#Dynamic {
		padding: 10px;
		margin-top: -15px;
		box-sizing: border-box;
	}
	#DynamicMore{
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		width: 375px;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.8);
		z-index: 1000;
		
		.Dynamic_wrapper{
			position: relative;
			&:before{
				content: '';
				position: absolute;
				width: 1px;
				height: 100%;
				// background: #ffba2e;
				left: 50%;
				top: 10px;
				bottom: 10px;
				transform: translateX(-50%);
				z-index: 10;
				background: linear-gradient(180deg, rgba(49, 213, 255, 0.00) 0%, #31D5FF 52.08%, rgba(49, 213, 255, 0.00) 100%);
				box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.10);
				mask-image:linear-gradient(90deg,rgba(25,25,25,0) 4%,#000 20%,#000 80%,rgba(25,25,25,0) 96%);
    		-webkit-mask-image:linear-gradient(90deg,rgba(25,25,25,0) 4%,#000 20%,#000 80%,rgba(25,25,25,0) 96%);
			}
			.bar{
				background-image: url(~@/assets/img/bar-top.png);
				background-size: 100% 100%;
				width: 375px;
				height: 27px;
				margin: 5px 0;
				&.bar-bottom{
					background-image: url(~@/assets/img/bar-bottom.png);
				}
			}
		}
	}
	.Dynamic_box {
		width: 315px;
		height: 78px;
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		mask-image:linear-gradient(90deg,rgba(25,25,25,0) 4%,#000 20%,#000 80%,rgba(25,25,25,0) 96%);
    -webkit-mask-image:linear-gradient(90deg,rgba(25,25,25,0) 4%,#000 20%,#000 80%,rgba(25,25,25,0) 96%);
		.Dynamic_list{
			display: flex;
			gap: 6px;
			margin: 3px;
			transition: 6s cubic-bezier(0, 0, 0.28, 1);
			transform: translateX(0);
			&.active{
				transform: translateX(-3560px);
			}
			.Dynamic_item{
				flex-shrink: 0;
				background: linear-gradient(180deg, #1C1C2C 0%, #0B0B12 100%);
				.img-con{
					width: 112px;
					height: 78px;
					background-size: auto 100%;
					background-position: center center;
					background-repeat: no-repeat;
					&.lv1{
						background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
					}
					&.lv2{
						background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
					}
					&.lv3{
						background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
					}
					&.lv4{
						background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
					}
					&.lv5{
						background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
					}
				}
			}
		}
		
	}
</style>
