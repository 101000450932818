<template>
	<div class="details">
		<div class="rule-tit" @click="openRule()">
			玩法规则
			<img src="@/assets/img/icon-help.png" alt="">
		</div>
		<div class="details-main">
			<div v-show="DetailData.per_day_times>0" class="limit">
				<span>每日开启：{{DetailData.per_day_times-DetailData.times}}/{{DetailData.per_day_times}}</span>
			</div>
			<div class="img-con">
				<div class="front" :style="`background-image: url(${DetailData.cover})`"></div>
				<img :src="DetailData.weapon_cover" alt="">
			</div>
		</div>
		<!-- <div class="Details_box">
			<div class="Details_box_pic" :style="{'background-image': 'url('+DetailData.weapon_cover+')'}"  :class="this.$route.name == 'Lucky_open' ? 'active' : ''">
				<img v-show="this.$route.name != 'Activity_open'" :src="DetailData.cover" alt="">
			</div>
			<p v-show="this.$route.name != 'Activity_open'" class="Details_Price" style='color:#fff'><img src="@/assets/images/public/Gold.png" alt="">{{bean}}</p>
		</div> -->
	</div>
</template>

<script> 
	export default{
		name:'Details',
		props:{
			DetailData:{//宝箱数据
				type:Object
			},
			NumIdx:{//开启数量
				type:Number
			}
		},		
		data(){
			return{		
			}
		},
		computed:{
			bean(){
				return parseInt(this.DetailData.bean * (this.NumIdx)*100)/100
			}
		},
		methods: {
			openRule(){
				this.$emit("openRule")
			},
		}
	}
</script>

<style lang="scss">
	.details{
		display: flex;
		align-items: center;
		position: relative;
		flex-direction: column;
		.rule-tit{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			font-size: 12px;
			width: 100%;
			padding-right: 10px;
			color: #999;
			cursor: pointer;
			img{
				width: 10px;
				height: 10px;
			}
		}
		.details-main{
			width: 240px;
			height: 240px;
			position: relative;
			.limit{
				position: absolute;
				left: 16px;
				bottom: 20px;
				z-index: 2;
				background-image: url(~@/assets/img/limit-btn-bg.png);
				background-size: 100% 100%;
				min-width: 90px;
				height: 25px;
				line-height: 25px;
				font-size: 12px;
				padding: 0 6px;
				text-align: center;
				span{
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
			.img-con{
				position: relative;
				z-index: 1;
				width: 100%;
				height: 100%;
				img{
					position: relative;
					z-index: 1;
				}
				.front{
					background-size: 100% auto;
					background-repeat: no-repeat;
					background-position: center center;
					position: absolute;
					z-index: 10;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
				}
			}
		}
	}
</style>
